
import { DxChart, DxSeries } from 'devextreme-vue/chart';
import { dataSource } from './../data/data8.js';

export default {
  components: {
    DxChart,
    DxSeries,
  },
  data() {
    return {
      dataSource,
    };
  },
};
