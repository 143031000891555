export const dataSource = [{
  day: 'OT0001-Asesoria Global Ambiental Tipo Poliza',
  oranges: 30,
}, {
  day: 'OT0002-Asesoria en Procedimiento Administrativo',
  oranges: 12,
}, {
  day: 'OT0003-Estudio Ambiental Especifico',
  oranges: 3,
}, {
  day: 'OT0004-Evaluaciones Ambientales Laboratorio',
  oranges: 1,
}];
